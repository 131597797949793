import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { Layout, Segmented, Modal } from "antd";
import { apiPaths, hyperflowPaths } from "@mirinae/defines/paths";
import { useAppStore, useAppStoreMethods } from "@hyperflow/modules/stores/app";
import { useFlowGraphStore } from "@hyperflow/modules/stores/flowgraph";
import { httpAPI } from "@mirinae/apis/http";
import { useUserStore } from "@mirinae/stores/user";
import { useProjectStore } from "@hyperflow/modules/stores/project";
import { useViewerStoreMethods } from "@hyperflow/modules/stores/viewer";

console.log("apiPaths", apiPaths, "hyperflowPaths", hyperflowPaths);

const { Header, Content } = Layout;

const HyperFlowLayout = ({ mode, HeaderMenu, children, admin }) => {
    const { setAppMode } = useAppStoreMethods();
    const flowGraph = useFlowGraphStore(state => state.flow.flowGraph);
    const userMethods = useUserStore(state => state.methods);
    const { project, methods: projectMethods } = useProjectStore(state => state);
    const { setEditingEnabled } = useViewerStoreMethods();
    const navigate = useNavigate();

    useEffect(() => {
        document.body.id = mode;
        setAppMode(mode);
        setEditingEnabled(flowGraph, mode);
    }, []);

    const goHome = () => navigate(hyperflowPaths.home);

    const logout = async _ => {
        await httpAPI("", apiPaths.signOut, { method: "GET" });
        localStorage.clear();
        projectMethods.clearProject();
        userMethods.unsetUser();
    };

    return (
        <MainAppLayoutLayout>
            <MainAppLayoutHeader>
                <LogoBlock onClick={goHome}>
                    <Logo src="/assets/images/hyperflow-logo.svg" />
                    <LogoText>
                        <strong>Hyper</strong>Flow AI
                    </LogoText>
                </LogoBlock>
                {mode !== "testchatbot" ? (
                    <>
                        <CenterBlock>
                            <ProjectName>{project ? project.name : "--"}</ProjectName>
                            <span>/</span>
                            <FlowGraphName>{flowGraph ? flowGraph.displayName : "--"}</FlowGraphName>
                            {HeaderMenu && <HeaderMenu />}
                        </CenterBlock>
                        <Btns>
                            <button onClick={() => navigate(hyperflowPaths.admin)}>Admin</button>
                            <button onClick={logout}>Logout</button>
                        </Btns>
                    </>
                ) : (
                    flowGraph && <FlowGraphTitle>{flowGraph.displayName}</FlowGraphTitle>
                )}
                <Modal
                    style={{ zIndex: 3000 }}
                    open={!admin && project.name == null && mode !== "testchatbot"}
                    closable={false}
                    footer={
                        <div>
                            <a onClick={() => navigate(hyperflowPaths.admin)}>admin</a>
                        </div>
                    }
                >
                    Select a project
                </Modal>
            </MainAppLayoutHeader>
            <MainAppLayoutContent>{children}</MainAppLayoutContent>
        </MainAppLayoutLayout>
    );
};

const MainAppLayoutLayout = styled(Layout)`
    min-height: 100vh;
    height: 100%;
`;

const MainAppLayoutHeader = styled(Header)`
    display: flex;
    flex-direction: row;
    position: relative;
    background: white; // #f9fafb;
    border-bottom: 1px solid #d3dae2; // #edf0f3;
    align-items: center;
    padding-left: 25px;
    height: 45px;
`;

const MainAppLayoutContent = styled(Content)`
    display: flex;
    flex-direction: column;
    position: relative;
    background: #f1f5f9; // white;
    background-image: radial-gradient(circle, #e5e5e5 1px, transparent 1px);
    background-size: 16px 16px;
    // padding: 20px;
`;

const LogoBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 10px;
    gap: 10px;
`;

const Logo = styled.img`
    height: 32px;
    cursor: pointer;
`;

const LogoText = styled.div`
    background: white;
    color: #8c8c8c;
    font-size: 20px;
    line-height: 44px;
    cursor: pointer;

    strong {
        font-weight: 700;
        color: #40a9ff;
    }
`;

const FlowGraphTitle = styled.div`
    margin: auto;
    line-height: 44px;
    font-size: 18px;
    color: #8c8c8c;
`;

const CenterBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: var(--Title-Small-Size, 14px);
    font-weight: 500;
    gap: 8px;

    span {
        color: var(--Neutral-Text-color-text-disabled, #99999d);
    }
`;

const ProjectName = styled.div`
    color: var(--Neutral-Text-color-text-tertiary, #99999d);
`;

const FlowGraphName = styled.div`
    color: var(--Neutral-Text-color-text-primary, #1a1a1b);
`;

const mainModeOptions = [
    { label: "Editor", value: "editor" },
    { label: "Runner", value: "runner" },
    { label: "Histories", value: "histories" },
    { label: "Browser", value: "browser" },
    { label: "Simulator", value: "simulator", onlyIfSelectedFlowGraph: true },
];

export const AppModes = ({}) => {
    const mode = useAppStore(state => state.appMode);
    const { setAppMode } = useAppStoreMethods();
    const flowGraph = useFlowGraphStore(state => state.flow.flowGraph);
    const userMethods = useUserStore(state => state.methods);
    const { project, methods: projectMethods } = useProjectStore(state => state);
    const navigate = useNavigate();

    const modeOptions = useMemo(
        () => mainModeOptions.map(opt => (opt.onlyIfSelectedFlowGraph && !flowGraph ? { ...opt, disabled: true } : opt)),
        [flowGraph]
    );

    useEffect(() => {
        document.body.id = mode;
        setAppMode(mode);
    }, []);

    const onModeChange = mode => {
        setAppMode(mode);
        // by default, add any selected flowGraph ID as a query-param
        const pageURL = `${hyperflowPaths[mode]}${flowGraph ? `?fg=${flowGraph._id.toString()}` : ""}`;
        navigate(pageURL);
    };

    return (
        mode !== "testchatbot" &&
        project.name != null &&
        mode && <MainModeSegments options={modeOptions} value={mode} onChange={onModeChange} size="medium" />
    );
};

const MainModeSegments = styled(Segmented)`
    background: white;
`;

const Btns = styled.div`
    position: absolute;
    right: 20px;
    display: flex;
    gap: 10px;
`;

export default HyperFlowLayout;
