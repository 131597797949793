import { useEffect } from "react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

import { DirectoryNode } from "@hyperflow/modules/classes/DirectoryNodes";
import { useTagTreeStore } from "@hyperflow/modules/stores/tagTree";
import { useFlowGraphStore } from "@hyperflow/modules/stores/flowgraph";
import { escapeRegExp } from "@mirinae/shared/modules/utils/formatters";

// shared state store for the tag-based directory
// note there are two instances of this store, one for the main directory in the left pane, another for the popup directory-selector
// in the parameter UI so that tree collapse & other state is separated.   We might need more instances if there are to be more directories
// instances that should maintain separatory directory display state, etc.
//
// as of now, all the stores needing a copy of the tag-tree nodeMap will subscribe to a shared instance maintained by the TagTree zustand store

const initStore = {
    nodeMap: {},
    rootNodeID: 1,
    selectedNodeID: -1,
};

const createDirectoryStore = storeInit =>
    create(
        subscribeWithSelector((set, get) => {
            // grab tagTree nodemap & subscribe to its updates
            const { nodeMap, rootNodeID, methods: tagTreeMethods } = useTagTreeStore.getState();

            const state = {
                ...storeInit,

                // set up shared tagTree store access & updates subscription
                nodeMap,
                rootNodeID,
                tagTreeMethods,

                subscribeToTagTreeStore: () => {
                    const unsubscribe = useTagTreeStore.subscribe(
                        state => ({ nodeMap: state.nodeMap, rootNodeID: state.rootNodeID }),
                        ({ nodeMap, rootNodeID }) =>
                            set(
                                produce(state => {
                                    state.nodeMap = nodeMap;
                                    state.rootNodeID = rootNodeID;
                                })
                            )
                    );
                    return unsubscribe;
                },

                methods: {
                    setSelectedNode: nodeID =>
                        set(
                            produce(state => {
                                state.selectedNodeID = nodeID;
                            })
                        ),

                    filteredNodeMap: (filter, searchString) => {
                        const state = get();
                        const rootNode = { id: state.rootNodeID, parent: null, children: [] };
                        const filteredMap = { [rootNode.id]: rootNode };
                        const filtered = node => {
                            return Object.entries(filter).every(([prop, value]) => node[prop] === value);
                        };
                        const search = node => {
                            const searchPattern = searchString && new RegExp(escapeRegExp(searchString), "i");
                            return (
                                !searchPattern ||
                                node.tag?.match(searchPattern) ||
                                node.name?.match(searchPattern) ||
                                node.searchText?.match(searchPattern)
                            );
                        };
                        for (const node of Object.values(state.nodeMap)) {
                            if (node.isLeaf && filtered(node) && search(node)) {
                                filteredMap[node.id] = node;
                                let parent = state.nodeMap[node.parent];
                                while (parent.id !== state.rootNodeID) {
                                    filteredMap[parent.id] = parent;
                                    if (state.nodeMap[parent.parent].id === state.rootNodeID && !rootNode.children.includes(parent.id)) {
                                        rootNode.children.push(parent.id);
                                    }
                                    parent = state.nodeMap[parent.parent];
                                }
                            }
                        }
                        return filteredMap;
                    },

                    getNodeByID: id => get().nodeMap[id], // to avoid state-change rerender

                    openToLevel: level =>
                        set(
                            produce(state => {
                                for (const node of Object.values(state.nodeMap)) {
                                    if (!node.isLeaf) {
                                        state.nodeMap[node.id].open = node.level < level;
                                    }
                                }
                            })
                        ),

                    toggleSubtreeOpen: subtreeID =>
                        set(
                            produce(state => {
                                state.nodeMap[subtreeID].open = !state.nodeMap[subtreeID].open;
                            })
                        ),

                    toggleChecked: nodeID =>
                        set(
                            produce(state => {
                                const checked = !state.nodeMap[nodeID].checked;
                                const setSubtree = id => {
                                    state.nodeMap[id].checked = checked;
                                    state.nodeMap[id].children?.forEach(cid => setSubtree(cid));
                                };
                                setSubtree(nodeID);
                            })
                        ),

                    oneChecked: nodeID =>
                        set(
                            produce(state => {
                                for (const node of Object.values(state.nodeMap)) {
                                    state.nodeMap[node.id].checked = node.id === nodeID;
                                }
                            })
                        ),

                    clearAllChecks: () =>
                        set(
                            produce(state => {
                                for (const node of Object.values(state.nodeMap)) {
                                    state.nodeMap[node.id].checked = false;
                                }
                            })
                        ),

                    getCheckedSelection: () => {
                        const state = get();
                        let display,
                            highestLevel = 1e6;
                        const selection = Object.values(state.nodeMap)
                            .filter(node => node.isLeaf && node.checked)
                            .map(node => {
                                if (!display) display = node.name;
                                let parent = state.nodeMap[node.parent];
                                while (parent.id !== state.rootNodeID) {
                                    if (parent.checked && parent.level < highestLevel) {
                                        display = `${parent.tag}...`; // `${parent.name}...`;
                                        highestLevel = parent.level;
                                    }
                                    parent = state.nodeMap[parent.parent];
                                }
                                return node; // why this earlier:  { ...node };
                            });
                        return { display, selection };
                    },

                    triggerReload: () =>
                        set(
                            produce(state => {
                                state.reloadTrigger = state.reloadTrigger + 1;
                            })
                        ),

                    reset: () =>
                        set(
                            produce(state => {
                                state = cloneDeep(storeInit);
                            })
                        ),
                },
            };

            // manage tagTree sharing, BUT note that we are not easily able to garbage-collect these subscriptions internally, needs to be in directorystore users??
            const unsubscribe = state.subscribeToTagTreeStore();
            // useEffect(() => {
            //     return () => {
            //         unsubscribe();
            //     };
            // }, []);

            return state;
        })
    );

// we make two directory stores, one for the main directory panel, the other for the directory-selector widget
// maybe abstract this into a store factory function if more are needed, the dups below are a bit messy
const mainDirectoryStore = createDirectoryStore({ ...cloneDeep(initStore), store: "main" });
const directorySelectorStore = createDirectoryStore({ ...cloneDeep(initStore), store: "selector" });

export const useDirectoryStore = mainDirectoryStore;
export const useDirectoryStoreMethods = () => mainDirectoryStore(state => state.methods, shallow);
export const useDirectoryStoreAndMethods = (stateGetter, flags) => [
    mainDirectoryStore(stateGetter, flags),
    mainDirectoryStore(state => state.methods, shallow),
];
export const useDirectoryStoreVars = (
    ...args // ('var1', 'var2', ...)
) => mainDirectoryStore(state => args.map(prop => state[prop]), shallow);

export const useDirectorySelectorStore = directorySelectorStore;
export const useDirectorySelectorStoreMethods = () => directorySelectorStore(state => state.methods, shallow);
export const useDirectorySelectorStoreAndMethods = (stateGetter, flags) => [
    directorySelectorStore(stateGetter, flags),
    directorySelectorStore(state => state.methods, shallow),
];
export const useDirectorySelectorStoreVars = (
    ...args // ('var1', 'var2', ...)
) => directorySelectorStore(state => args.map(prop => state[prop]), shallow);
