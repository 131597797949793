import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

import { getByPath, setByPath } from "@mirinae/shared/modules/utils/pathUtils";

const initStore = {
    bot: {
        flowGraph: null,
        showChatbot: false,
        userPrompt: "",
        promptParamUI: null,
        redrawTrigger: 0,
        chatHistory: [], // { type: 'user|generator|message|promptButton', text: 'xxx', label: '<label-for-prompt-button' }
        busyMessage: "",
    },
};

export const useChatbotStore = create(
    subscribeWithSelector((set, get) => ({
        ...cloneDeep(initStore),

        methods: {
            // this is deprecated, we now just view the nodes & edges in the current flowgraph store flow.flowGraph
            openFlowGraphInChatbot: flowGraph =>
                set(
                    produce(state => {
                        state.bot.flowGraph = flowGraph;
                        state.bot.showChatbot = true;
                        state.bot.chatHistory = [];
                        state.bot.busyMessage = "";
                        state.bot.promptParamUI = {};
                    })
                ),

            setShowChatbot: show =>
                set(
                    produce(state => {
                        state.bot.showChatbot = show;
                    })
                ),

            clearChathistory: () =>
                set(
                    produce(state => {
                        state.bot.chatHistory = [];
                        state.bot.busyMessage = "";
                        state.bot.promptParamUI = {};
                    })
                ),

            addToChat: chatEntry =>
                set(
                    produce(state => {
                        state.bot.chatHistory.push(chatEntry);
                    })
                ),

            setChatPromptParamUI: paramUI =>
                set(
                    produce(state => {
                        state.bot.promptParamUI = paramUI;
                    })
                ),

            triggerChatbotRedraw: () =>
                set(
                    produce(state => {
                        state.bot.redrawTrigger += 1;
                    })
                ),

            setBusyMessage: busyMessage =>
                set(
                    produce(state => {
                        state.bot.busyMessage = busyMessage;
                    })
                ),

            reset: () =>
                set(
                    produce(state => {
                        state = cloneDeep(initStore);
                    })
                ),
        },
    }))
);

export const useChatbotStoreMethods = () => useChatbotStore(state => state.methods, shallow);
export const useChatbotStoreAndMethods = (stateGetter, flags) => [
    useChatbotStore(stateGetter, flags),
    useChatbotStore(state => state.methods, shallow),
];
export const useChatbotStoreVars = (
    ...args // ('var1', 'var2', ...)
) => useChatbotStore(state => args.map(prop => state[prop]), shallow);
