import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

import { getByPath, setByPath } from "@mirinae/shared/modules/utils/pathUtils";

// shared state store for the Property Inspector panels

const initStore = {
    viewInfo: [],
    reloadTrigger: 0,
};

const createInspectorStore = storeInit =>
    create(
        subscribeWithSelector((set, get) => ({
            ...storeInit,

            methods: {
                setViewInfo: viewInfo =>
                    set(
                        produce(state => {
                            state.viewInfo = viewInfo;
                        })
                    ),

                triggerReload: () =>
                    set(
                        produce(state => {
                            state.reloadTrigger = state.reloadTrigger + 1;
                        })
                    ),

                reset: () =>
                    set(
                        produce(state => {
                            state = cloneDeep(storeInit);
                        })
                    ),
            },
        }))
    );

// make as many inspector stores as needed for each instance of the inspector panel??
const mainInspectorStore = createInspectorStore({ ...cloneDeep(initStore), store: "main" });
// const InspectorSelectorStore = createInspectorStore({ ...cloneDeep(initStore), store: 'selector' });

export const useInspectorStore = mainInspectorStore;
export const useInspectorStoreMethods = () => mainInspectorStore(state => state.methods, shallow);
export const useInspectorStoreAndMethods = (stateGetter, flags) => [
    mainInspectorStore(stateGetter, flags),
    mainInspectorStore(state => state.methods, shallow),
];
export const useInspectorStoreVars = (
    ...args // ('var1', 'var2', ...)
) => mainInspectorStore(state => args.map(prop => state[prop]), shallow);

// export const useInspectorSelectorStore = InspectorSelectorStore;
// export const useInspectorSelectorStoreMethods = () =>
//     InspectorSelectorStore((state) => state.methods, shallow);
// export const useInspectorSelectorStoreAndMethods = (stateGetter, flags) => [
//     InspectorSelectorStore(stateGetter, flags),
//     InspectorSelectorStore((state) => state.methods, shallow),
// ];
// export const useInspectorSelectorStoreVars = (...args) => // ('var1', 'var2', ...)
//     InspectorSelectorStore(state => args.map(prop => state[prop]), shallow);
