import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

import { DirectoryNode } from "@hyperflow/modules/classes/DirectoryNodes";
import { useEffect } from "react";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import { escapeRegExp } from "@mirinae/shared/modules/utils/formatters";

// keeps an up-to-date copy of the app's taggings in a tree form suitable for hierarchical directories & menus

const initStore = {
    nodeMap: null,
    rootNodeID: 1,
    objectMap: null,
    reloadTriggered: 0,
};

export const useTagTreeStore = create(
    subscribeWithSelector((set, get) => ({
        ...cloneDeep(initStore),

        methods: {
            reloadTagTree: projectID => {
                httpAPI("", apiPaths.getTagTree, {
                    data: { projectID },
                })
                    .then(response => {
                        const {
                            data: { nodeMap, rootNodeID },
                        } = response;
                        const objectMap = {};
                        // wrap leaf nodes in instances of the DirectoryNode hierarchy
                        for (const [id, node] of Object.entries(nodeMap)) {
                            if (node.isLeaf) {
                                nodeMap[id] = DirectoryNode.makeFrom(node);
                                const ome = objectMap[node.objectID] || [];
                                ome.push(node);
                                objectMap[node.objectID] = ome;
                            }
                        }
                        set(
                            produce(state => {
                                state.nodeMap = nodeMap;
                                state.objectMap = objectMap;
                                state.rootNodeID = rootNodeID;
                                state.reloadTriggered = state.reloadTriggered + 1;
                            })
                        );
                    })
                    .catch(error => {
                        console.log("*** loading tag-tree failed!!", error.toString());
                    });
                set(produce(state => {}));
            },

            // may not be needed, always loaded in triggerReload???
            setNodeMap: (nodeMap, rootNodeID) => {
                // wrap leaf nodes in instances of the DirectoryNode hierarchy
                for (const [id, node] of Object.entries(nodeMap)) {
                    if (node.isLeaf) {
                        nodeMap[id] = DirectoryNode.makeFrom(node);
                    }
                }
                set(
                    produce(state => {
                        state.nodeMap = nodeMap;
                        useTagTreeStoreMethods;
                        state.rootNodeID = rootNodeID;
                    })
                );
                return nodeMap;
            },

            filteredNodeMap: (filter, searchString, tagPrefix) => {
                const state = get();
                const rootNode = {
                    id: state.rootNodeID,
                    parent: null,
                    children: [],
                };
                const filteredMap = { [rootNode.id]: rootNode };
                const filtered = node => {
                    return (
                        (!tagPrefix || node.tag.startsWith(tagPrefix)) &&
                        Object.entries(filter).every(
                            ([prop, value]) => node[prop] === value || (Array.isArray(value) && value.includes(node[prop]))
                        )
                    );
                };
                const search = node => {
                    const searchPattern = searchString && new RegExp(escapeRegExp(searchString), "i");
                    return (
                        !searchPattern ||
                        node.tag?.match(searchPattern) ||
                        node.name?.match(searchPattern) ||
                        node.searchText?.match(searchPattern)
                    );
                };
                for (const node of Object.values(state.nodeMap)) {
                    if (node.isLeaf && filtered(node) && search(node)) {
                        filteredMap[node.id] = node;
                        let parent = state.nodeMap[node.parent];
                        while (parent.id !== state.rootNodeID) {
                            filteredMap[parent.id] = parent;
                            if (state.nodeMap[parent.parent].id === state.rootNodeID && !rootNode.children.includes(parent.id)) {
                                rootNode.children.push(parent.id);
                            }
                            parent = state.nodeMap[parent.parent];
                        }
                    }
                }
                return filteredMap;
            },

            getNodeByID: id => get().nodeMap?.[id], // to avoid state-change rerender

            getLeafNodesByTag: tag => {
                return Object.values(get().nodeMap || {}).filter(n => n.isLeaf && n.tag.startsWith(tag));
            },

            getNodeTreeMenu: (filter, keyPrefix, subMenuClassName) => {
                // build ant.d Menu structures for the selected node tree
                const {
                    rootNodeID,
                    methods: { filteredNodeMap },
                } = get();
                const sortedNodes = nodes => nodes.sort((a, b) => (a.label < b.label ? -1 : a.label === b.label ? 0 : 1));
                const menuItemForNodeID = (nodeID, nodeMap, idMap) => {
                    const node = nodeMap[nodeID];
                    const item = {
                        key: node.isLeaf ? `${keyPrefix}.${nodeID}` : nodeID,
                        label: node.name,
                        node,
                    };
                    if (subMenuClassName) item.popupClassName = subMenuClassName;
                    if (!node.isLeaf) {
                        item.children = sortedNodes(node.children.map(childID => menuItemForNodeID(childID, nodeMap, idMap)));
                    } else if (idMap) {
                        idMap[node.objectID] = item.key;
                    }
                    return item;
                };

                const treeNodeMap = filteredNodeMap(filter);
                const menuItems = [];
                for (const topLevelNodeID of treeNodeMap[rootNodeID].children) {
                    menuItems.push(menuItemForNodeID(topLevelNodeID, treeNodeMap));
                }
                return { menuItems, treeNodeMap, rootNodeID };
            },

            reset: () =>
                set(
                    produce(state => {
                        state = cloneDeep(initStore);
                    })
                ),
        },
    }))
);

export const useTagTreeStoreMethods = () => useTagTreeStore(state => state.methods, shallow);
export const useTagTreeStoreAndMethods = (stateGetter, flags) => [
    useTagTreeStore(stateGetter, flags),
    useTagTreeStore(state => state.methods, shallow),
];
export const useTagTreeStoreVars = (
    ...args // ('var1', 'var2', ...)
) => useTagTreeStore(state => args.map(prop => state[prop]), shallow);
