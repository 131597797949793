import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

// this is  global app state store, for coordinating the app across all use modes

const initStore = {
    appMode: "",

    directory: {
        openKeys: {},
        selectedKeys: {},
        treeRootKey: {},
    },
};

export const useAppStore = create(
    subscribeWithSelector((set, get) => ({
        ...cloneDeep(initStore),

        methods: {
            setAppMode: mode =>
                set(
                    produce(state => {
                        state.appMode = mode;
                    })
                ),

            setOpenKeys: (tree, keys) =>
                set(
                    produce(state => {
                        state.directory.openKeys[tree] = keys;
                    })
                ),

            setSelectedKeys: (tree, keys) =>
                set(
                    produce(state => {
                        state.directory.selectedKeys[tree] = keys;
                    })
                ),

            setTreeRootKey: (tree, key) =>
                set(
                    produce(state => {
                        state.directory.treeRootKey[tree] = key;
                    })
                ),

            reset: () =>
                set(
                    produce(state => {
                        state = cloneDeep(initStore);
                    })
                ),
        },
    }))
);

export const useAppStoreMethods = () => useAppStore(state => state.methods, shallow);
export const useAppStoreAndMethods = (stateGetter, flags) => [
    useAppStore(stateGetter, flags),
    useAppStore(state => state.methods, shallow),
];
export const useAppStoreVars = (
    ...args // ('var1', 'var2', ...)
) => useAppStore(state => args.map(prop => state[prop]), shallow);
