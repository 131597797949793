/* eslint-disable no-underscore-dangle */
import { useEffect } from "react";
import { apiPaths } from "@mirinae/shared/modules/defines/paths";

let _rootDomain;
let _cookieDomain;
// if (!process.env.REACT_APP_IS_ALPHA && (process.env.REACT_APP_MODE === 'LOCAL' || process.env.REACT_APP_LOCAL)) { //  HEY, need to be able to recognize app source for  localhost testing!!  only way now is https://[app.]local.mirinae.io with local Nginx SSL terminator
if (window.location.hostname.match(/local/)) {
    _rootDomain = "localhost";
    _cookieDomain = "localhost";
} else if (window.location.hostname.match(/((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}/)) {
    _rootDomain = "HYPERFLOW_IP";
    _cookieDomain = "HYPERFLOW_IP";
} else {
    _rootDomain = window.location.host.match(/((.+\.)|(^))([a-z\-]+\.[a-z][a-z][a-z]*)$/)[4];
    _cookieDomain = `.${_rootDomain}`;
}
export const rootDomain = _rootDomain;
export const cookieDomain = _cookieDomain;

// pull in each apps local leaf paths (used in their local <Route>ing) to build absolute paths for every URL in every app, for sharing
import { localPaths as hyperflowLocalPaths } from "@mirinae/hyperflow/modules/defines/paths";
import { localPaths as homeAppLocalPaths } from "@mirinae/apps/home-app/modules/defines/paths";

// the apps' root paths
export const appRoots = {
    hyperFlow: "hyperflow",
    homeApp: "home",
};

// build external URL, including any flutter app version parameter, so other services know app version
export const buildExternalURL = (path, versionParam) => {
    const vp = versionParam || window.versionParam || window.appVersion;
    const qpSep = path.indexOf("?") > 0 ? "&" : path.endsWith("/") ? "?" : "/?";
    return vp ? `${path}${qpSep}v=${vp}` : path;
};

// construct full paths for all pages across all apps
export const hyperflowPaths = Object.entries(hyperflowLocalPaths).reduce((m, [pathKey, localPath]) => {
    m[pathKey] = `/${appRoots.hyperFlow}${localPath ? "/" : ""}${localPath}`;
    return m;
}, {});
export const homeAppPaths = Object.entries(homeAppLocalPaths).reduce((m, [pathName, localPath]) => {
    m[pathName] = `/${appRoots.homeApp}${localPath ? "/" : ""}${localPath}`;
    return m;
}, {});

// external link redirector component
export const ExternalRedirector = ({ to, reload = false, versionParam }) => {
    const url = buildExternalURL(to, versionParam);
    useEffect(() => {
        if (reload) window.location.href = url;
        else window.location.replace(url);
    }, []);
    return null;
};

export const navigateExternal = (_url, options = {}) => {
    const url = buildExternalURL(_url, options.versionParam);
    if (options.replace) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
};

export { apiPaths };

export const wsPaths = {
    connect: `${
        window.location.hostname === "localhost" ? "ws://localhost:2000" : "wss://" + window.location.hostname
    }/ws/flowgraph/connect`,
};
